document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {

        // Frontpage header
        if ($(window).width() > 992) {
            $(window).scroll(function () {
                const scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    $('header .container-fluid').addClass('white');
                } else {
                    $('header .container-fluid').removeClass('white')
                }
            });
        } else {
            $('header .container-fluid').addClass('mobile white');
        }

        // Search
        const searchform = $('.search-form');
        $('#search p').click(function () {
            searchform.toggleClass('open');
        });

        // Mobile menu
        $('.mobile-menu-button').click(function () {
            $(this).toggleClass('open');
            $('.header-logo').toggleClass('hidden');
            $('.mobile-menu-container').toggleClass('open');
        });

        $('.open-sub-menu').click(function (e) {
            e.preventDefault();
            $(this).parent().parent().toggleClass('open');
        });

    });
})(jQuery);
